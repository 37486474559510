import React, {Component} from 'react';
import Loading from './components/utils/Loading';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import RedeemedViewer from "./components/main_screens/RedeemedViewer";
import {base} from './base';
import ReactGA from 'react-ga';
import './styles/css/appstyles.css';
import { getURLParameter } from './components/utils/HelpfulFunction';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
  constructor(props) {
        super(props);
        const passedCode = getURLParameter('passedcode') || false;
        const passedSquareId = getURLParameter('passedsquareid') || false;
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = localStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false,
            passedCode: passedCode,
            passedSquareId: passedSquareId
        }
    }

  componentDidMount() {
      this.tenantRulesRef = base.listenTo(`tenantRules`, {
          context: this,
          then(variables){
              this.setState({
                  tenantRulesVariables: variables
              })
          }
      });
    this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
      context: this,
      state: 'tenantVariables',
      then(variables){
          variables.backgroundColor = variables.backgroundColor || "black";
          variables.primaryColor = variables.primaryColor || "transparent";
          variables.secondaryColor = variables.secondaryColor || "transparent";
          variables.textColor = variables.textColor || "white";
          document.title = variables.pageTitle || "QR Scavenger Hunt";
          this.setState({
            authenticated: false,
            variables: variables,
            loading: false,
        })
      }
    });
  }

  setCurrentUser() {
        const userEmail = localStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

  checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = localStorage.getItem('verifiedAgeRSL') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
  }

  removePassedCode(vm){
        vm.setState({
          passedCode: false,
          passedSquareId: false
        })
        const uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            const clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }
  }

  setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.tenantRulesRef);
  }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} variables={this.state.variables}/>
        )
    }
    let addSearchParams = "";
    if(window.location.search){
        addSearchParams = window.location.search;
    }
    console.log(this.state.tenantRulesVariables)
    return (
        <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
                <div>
                    <div className="main-content">
                        <div className="workspace">
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    render={(props) => {
                                        return <Login
                                            authenticated={this.state.authenticated}
                                            loadPage={() => logPageView()}
                                            setCurrentUser={this.setCurrentUser}
                                            setPassed={(setPassed)=>this.setPassedEmail(setPassed)}
                                            variables={this.state.variables}
                                            checkForAgeGate={this.checkForAgeGate}
                                            tenantRulesVariables={this.state.tenantRulesVariables}
                                            {...props} />
                                    }}
                                />

                                <AuthenticatedRoute
                                    exact
                                    path="/"
                                    authenticated={this.state.authenticated}
                                    variables={this.state.variables}
                                    passedEmail={this.state.passedEmail}
                                    loadPage={() => logPageView()}
                                    checkForAgeGate={this.checkForAgeGate}
                                    passedCode={this.state.passedCode}
                                    passedSquareId={this.state.passedSquareId}
                                    removePassedCode={this.removePassedCode}
                                    tenantRulesVariables={this.state.tenantRulesVariables}
                                    component={MainApp} />

                                {/*<Route*/}
                                {/*    exact*/}
                                {/*    path="/"*/}
                                {/*    render={(props) => {*/}
                                {/*        return <MainApp setPassed={(setPassed)=>this.setPassedEmail(setPassed)} variables={this.state.variables} passedCode={this.state.passedCode} passedSquareId={this.state.passedSquareId} removePassedCode={this.removePassedCode} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <Route
                                    path="/redeemviewer"
                                    render={(props) => {
                                        return <RedeemedViewer
                                            loadPage={() => logPageView()}
                                            tenantVariables={this.state.variables}
                                            tenantRulesVariables={this.state.tenantRulesVariables}
                                            {...props} />
                                    }} />
                                />

                                <Route
                                    exact
                                    path="/age_gate"
                                    render={(props) => {
                                        return <AgeVerification
                                            loadPage={() => logPageView()}
                                            variables={this.state.variables}
                                            checkForAgeGate={this.checkForAgeGate}
                                            tenantRulesVariables={this.state.tenantRulesVariables}
                                            {...props} />
                                    }} />

                                <Redirect
                                    to={{
                                        pathname: "/login",
                                        search: addSearchParams
                                    }}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    )
  }
}

export default App;
